<template>
  <b-nav-item>
    <feather-icon
      size="21"
      icon="BatteryIcon"
      class="success"
    />
    <span style="padding-left: 5px; font-size: 9pt;">1.5k</span>
  </b-nav-item>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  setup() {

  },
}
</script>
