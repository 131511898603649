<template>
  <b-nav-item
  >
  <!-- <b-img
        id="cfdi-advisor"
        :src="require('@/assets/images/icons/envelope.png')"
        height="22px"
        width="22px"
      /> -->
      <b-icon 
        v-if="weeksLeft <= 2 || !isCsdLoaded"
        id="cfdi-advisor"
        scale="1.3"
        icon="exclamation-circle-fill"
        variant="warning"
      />
      <b-icon 
        v-else 
        id="cfdi-advisor"
        icon="key"
        scale="1.3"
        class="rounded-circle"
      />
    <!-- <feather-icon
      id="cfdi-advisor"
      size="21"
      icon="KeyIcon"
      :class="weeksLeft <= 2 && isCsdLoaded ? 'csd-about-expires':'' "
    /> -->
    <!-- :title="t('Edit')" -->
    <b-tooltip
                :title="isCsdLoaded ? `${t('CSD Expiry')} ${dateFormatted}`: `${t('CSD not loaded')}`"
                class="cursor-pointer"
                :target="`cfdi-advisor`"
              />
  </b-nav-item>
</template>

<script>
import store from '@/store'
import { BNavItem , BTooltip, BImg, BIcon} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BNavItem,
    BTooltip,
    BImg,
    BIcon
  },
  setup() {
    const { t } = useI18nUtils();
    return {
      t,
    };
  },
  data(){
    return {
    }
  },
  mounted() {
    this.$http.post('/account/settings', {} )
      .then(response => {
          const expDate = response?.data?.businessSecurity?.certificates?.main?.notAfter
          store.commit('csd-advisor/expiry', expDate)
        })
  },
  computed:{
    isCsdLoaded() {
      const {isCsdLoaded} = store.getters['csd-advisor/currentState']
      return isCsdLoaded;
    },
    dateFormatted(){
      const {date} = store.getters['csd-advisor/currentState']
      return date
    },
    weeksLeft(){
      const {weeksLeft,} = store.getters['csd-advisor/currentState']
      return weeksLeft
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
