<template>
    <b-nav-item
        :to="{name: 'apps-e-commerce-product-details'}"
    >
      <feather-icon
          icon="ShoppingCartIcon"
          class="mr-50"
          size="21"
      />
    </b-nav-item>
  </template>
  
  <script>
//   import store from '@/store'
  import { BNavItem } from 'bootstrap-vue'
  
  export default {
    components: {
      BNavItem,
    },
    setup() {
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  