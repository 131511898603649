<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- lsandoval: 13/01/2022 Bookmarks hidding, while we build another stuff -->
    <!--<div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <bookmarks />
    </div>-->
    <!-- Right Col -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      
      <!--<search-bar />-->
      <!--<tokens/>-->
      <business-dropdown v-if="session && session.businessList && session.businessList.length > 0"/>
      
      <user-dropdown />
      <dark-toggler/>
      <csd-advisor/>
      <notification-dropdown />
      <!-- <buy-tokens v-if="session.ecommerce"/> -->
      <locale />
      
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
//import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import DarkToggler from './components/DarkToggler.vue'
import CsdAdvisor from './components/CsdAdvisor.vue'
import Tokens from './components/Tokens.vue'
import SearchBar from './components/SearchBar.vue'
import CartDropdown from './components/CartDropdown.vue'
import BusinessDropdown from './components/BusinessDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import BuyTokens from './components/BuyTokens.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    //Bookmarks,
    Locale,
    DarkToggler,
    Tokens,
    SearchBar,
    BusinessDropdown,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    CsdAdvisor,
    BuyTokens
  },
  data() {
    return {
      /** lsandoval: 25/01/2022 we use session instead of only userData */
      session: JSON.parse(localStorage.getItem('session')),
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  }
}
</script>
