/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
/**import dashboard from './dashboard' **/
//import apps from './apps'
//import pages from './pages'
//import chartsAndMaps from './charts-and-maps'
//import uiElements from './ui-elements'
//import formAndTable from './forms-and-table'
//import others from './others'

// Array of sections
/** lsandoval: 11/02/2022 Add the factura.green main routes */
let dashboard = {
  title: "Dashboard",
  icon: "HomeIcon",
  route: "dashboard-analytics",
};

let contacts = {
  title: "Contacts",
  icon: "UserIcon",
  route: "fg-contact-list",
};

let products = {
  title: "Products",
  icon: "PackageIcon",
  route: "fg-product-list",
};

let cfdis = {
  title: "Invoices",
  icon: "FileTextIcon",
  route: "fg-cfdi-list",
};

let home = {
  title: "Home",
  icon: "HomeIcon",
  route: "fg-home",
};
/** lsandoval: 11/02/2022 Set the factura.green main routes, only the core routes */
// export default [dashboard,contacts,products,cfdis]
/** lsandoval: 22/02/2022 Set the factura.green mvp  */
export default [home, contacts, products, cfdis];
/**
export default [dashboard,contacts,products,cfdis,...apps, ...uiElements, ...formAndTable, ...pages, ...chartsAndMaps, ...others]**/
