<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    menu-class="dropdown-menu-media"
    class="dropdown-cart mr-25"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav" @click="getTokens()">
        <p class="user-name font-weight-bolder mb-0" :id="'user-name'">
          {{
            session.business.name.length > 30
              ? session.business.name.substr(0, 30) + " ..."
              : session.business.name
          }}
        </p>
        <b-tooltip
          v-if="session.business.name.length > 30"
          class="cursor-pointer"
          :target="'user-name'"
        >
          {{ session.business.name }}
        </b-tooltip>
        <span class="user-status">{{ session.business.taxid }}</span>
      </div>

      <b-avatar
        size="40"
        :src="session.business.avatar"
        variant="light-info"
        :badge="tokenBundle.count <= 10"
        class="badge-minimal"
        badge-variant="danger"
      >
        <feather-icon v-if="session.business.name" icon="UsersIcon" size="22" @click="getTokens()"/>
      </b-avatar>
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      style="background-color: #efefef"
      @click="goToEcommerce"
    >
      <b-avatar
        size="32"
        :variant="tokenBundle.count <= 10 ? 'light-danger' : 'light-info'"
      >
        <!--<feather-icon
            icon="AlertTriangleIcon"
            size="16"
          />-->
        <feather-icon
          :icon="tokenBundle.count <= 10 ? 'AlertTriangleIcon' : 'InfoIcon'"
          size="16"
        />
      </b-avatar>

      <span
        style="
          position: relative;
          padding-left: 18px;
          margin-top: -12px;
          width: 100%;
          font-size: 10pt;
          font-weight: 500;
        "
       
        >{{ tokenLoading? t("folios.loading") : `${tokenBundle.count} ${t("folios")}  !!` }}</span
      >
      <span
        style="
          position: absolute;
          padding-left: 50px;
          margin-top: 14px;
          width: 100%;
          font-size: 8pt;
        "
        >{{ tokensMessage }}</span
      >
    </b-dropdown-item>
    <template v-if="session.businessAll == false">
      <b-dropdown-item @click.native.capture.stop>
        <b-form-input
          placeholder="Búsqueda"
          autofocus
          autocomplete="off"
          v-model="searchQuery"
        >
        </b-form-input>
      </b-dropdown-item>
    </template>
    <div
      style="
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        overflow-x: hidden;
      "
      class="scrollbar-simple"
    >
      <template
        v-for="(item, index) in searchList.length > 0
          ? searchList
          : session.businessList"
      >
        <b-dropdown-item
          v-if="item.taxid !== session.business.taxid"
          :key="index"
          link-class="d-flex align-items-center"
          @click="changeBusiness(item.i)"
        >
          <b-avatar size="32" :src="item.avatar" variant="light-info">
            <feather-icon v-if="item.name" icon="UserIcon" size="16" />
          </b-avatar>
          <div style="width: 100%; height: 100%; display: grid">
            <span
              :id="`business-item-${index}`"
              style="
                padding-left: 18px;
                width: 100%;
                height: 12pt;
                font-size: 10pt;
                font-weight: 500;
              "
              >{{
                item.name.length > 38
                  ? item.name.substr(0, 36) + " ..."
                  : item.name
              }}</span
            >
            <span
              style="
                padding-left: 22px;
                width: 100%;
                height: 10pt;
                font-size: 8pt;
              "
              >{{ item.taxid }}</span
            >
            <b-tooltip
              v-if="item.name.length > 38"
              class="cursor-pointer"
              :target="`business-item-${index}`"
            >
              <span style="font-size: 7.5pt">{{ item.name }}</span>
            </b-tooltip>
          </div>
        </b-dropdown-item>
      </template>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BDropdown,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BTooltip,
  BFormInput,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { t } from "@/@core/libs/i18n/utils";
import { watch } from "fs";
import store from '@/store/index'

export default {
  components: {
    BDropdown,
    BFormInput,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BTooltip,
  },
  setup() {
    /** lsandoval: 25/01/2022 We import the i18n utilities */
    const { t } = useI18nUtils();

    return {
      t,
    };
  },
  data() {
    return {
      session: JSON.parse(localStorage.getItem("session")),
      tokenBundle: JSON.parse(localStorage.getItem("tokenBundle")),
      searchList: [],
      searchQuery: "",
      showSearch: false,
      tokenLoading:false
    };
  },
  mounted() {
    this.$root.$on("tokens-refresh", (message) => {
      console.log("event-bus on(tokens-refresh)");
      this.tokenBundle = JSON.parse(localStorage.getItem("tokenBundle"));
    });
  },
  watch: {
    searchQuery: function (val) {
      this.filterBusiness(val);
    },
  },
  computed: {
    tokensMessage: {
      get() {
        if (this.tokenBundle.count === 0) {
          return "Adquiere un paquete!";
        }

        if (this.tokenBundle.isWT === true) {
          if (this.tokenBundle.count > 0 && this.tokenBundle.count <= 10) {
            return "Ya casi no tienes folios para continuar emitiendo, adquiere ya!";
          }

          return "Cliente que usa tus folios para emitir";
        }

        if (this.tokenBundle.count > 0 && this.tokenBundle.count <= 10) {
          return "Ya casi se te acaban, adquiere ya!";
        }

        return "";
      },
    },
  },
  methods: {

    getTokens(){
      this.tokenLoading = true;
      this.$http
        .post("s/token", { })
        .then((response) => {
          const tokenBundle = JSON.parse(localStorage.getItem("tokenBundle"));
          tokenBundle.count = response.data.tokens.count;
          this.tokenBundle = tokenBundle
          localStorage.setItem("tokenBundle",JSON.stringify(tokenBundle))
          this.tokenLoading = false;
        });
    },
    goToEcommerce(){
      this.$router.push({path:"/apps/e-commerce/personal"})
    },
    filterBusiness(text) {
      if (text.length > 3 && this.searchList.length <= 0) {
        // exec first search
        this.searchBusiness(text);
      }

      if (this.searchList.length > 0) {
        for (var item in this.searchList) {
          if (this.searchContains(item.name)) {
            this.searchBusiness(text);
          }
        }
      }

      if (text.length == 0) {
        this.searchList = [];
      }
    },

    searchBusiness(text) {
      this.$http
        .post("s/business/list", { params: { text: text } })
        .then((response) => {
          this.searchList = response.data.session.businessList;
        });
    },
    searchContains(n) {
      return this.searchList.indexOf(n) > -1;
    },
    changeBusiness(u) {
      this.$http.post("/s/change", { i: u }).then((response) => {
        const { session, tokenBundle } = response.data;

        useJwt.setToken(session.accessToken);
        useJwt.setRefreshToken(session.refreshToken);
        localStorage.setItem("session", JSON.stringify(session));
        localStorage.setItem("tokenBundle", JSON.stringify(tokenBundle));
        this.$ability.update(session.account.ability);
        this.session = session;
        this.tokenBundle = tokenBundle;
        this.searchList = [];
        (this.searchQuery = ""), this.$root.$emit("session-refresh", {});
        //this.$router.go()
        this.$http.post('/account/settings', {} )
        .then(response => {
          const expDate = response?.data?.businessSecurity?.certificates?.main?.notAfter
          store.commit('csd-advisor/expiry', expDate)
          location.reload(); // remove this if you do not want a reload after /s/change 
          
        })
        
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollbar-simple::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

.scrollbar-simple::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.scrollbar-simple::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.scrollbar-simple::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.scrollbar-simple::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}
</style>
